<template>
  <div class="page-container">
    <div class="l-boxs l-width-fill courier-container">
      <img
        class="house"
        style="z-index: 2"
        :src="require('@/static/image/icons/house.png')"
      />
      <div class="l-boxs box-p-10"></div>
      <div class="l-boxs box-p-20 box-p-t-32 box-p-b-20 l-flexh">
        <div class="avatar box-m-r-15">
          <img :src="userInfo.headimage" />
        </div>
        <div class="realname">
          <div class="l-flexh l-flexci-c">{{ userInfo.name }}</div>
          <div class="l-flexh l-flexci-c">{{ userInfo.mobile }}</div>
        </div>
      </div>
      <div
        class="l-boxs box-p-l-15 box-p-r-15"
        style="z-index: 99; position: relative"
      >
        <div class="info-container">
          <div class="info">
            <div class="money" @click="goToMoneyCenter">
              <div class="big">{{ userInfo.commission }}</div>
              <div class="small">佣金</div>
            </div>
            <div class="shuxian"></div>
            <div class="order-number" @click="goToOrderList">
              <div class="big">{{ userInfo.delivery_orders }}</div>
              <div class="small">配送次数</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <scrollview @onRefresh="onRefresh" :height="scrollHeight">
      <div class="l-boxs l-width-fill box-p-15 action-list">
        <div class="actions box-m-b-15" @click="goToNotice">
          <cell-v3>
            <img
              slot="icon"
              class="cell-icon"
              :src="require('@/static/image/icons/bell.png')"
            />
            <span class="title" slot="title">取货通知</span>
            <div class="action-icons">
              <!--<span class="dot" v-if="userInfo.notice_sum > 0">{{userInfo.notice_sum}}</span>-->
              <span v-if="userInfo.notice_sum > 0"
                >{{ userInfo.notice_sum }} 种商品等待取货</span
              >
              <img
                class="arrow-right"
                :src="require('@/static/image/icons/arrowRight.png')"
              />
            </div>
          </cell-v3>
        </div>

        <div class="actions box-m-b-15" @click="goToSendNotice">
          <cell-v3>
            <img
              slot="icon"
              class="cell-icon"
              :src="require('@/static/image/icons/bill-order.png')"
            />
            <span class="title" slot="title">配送中订单</span>
            <div class="action-icons">
              <span v-if="userInfo.goods_total > 0"
                >{{ userInfo.site_numbers }} 个自提点配送中</span
              >
              <img
                class="arrow-right"
                :src="require('@/static/image/icons/arrowRight.png')"
              />
            </div>
          </cell-v3>
        </div>

        <div class="actions box-m-b-15" @click="goToOrderList">
          <cell-v3>
            <img
              slot="icon"
              class="cell-icon"
              :src="require('@/static/image/icons/delivery-info.png')"
            />
            <span class="title" slot="title">配送成功记录</span>
            <div class="action-icons">
              <img
                class="arrow-right"
                :src="require('@/static/image/icons/arrowRight.png')"
              />
            </div>
          </cell-v3>
        </div>

        <div class="actions box-m-b-15" @click="goToBackGoods">
          <cell-v3>
            <img
              slot="icon"
              class="cell-icon"
              :src="require('@/static/image/icons/delivery-info.png')"
            />
            <span class="title" slot="title">待取回商品</span>
            <div class="action-icons">
              <span v-if="userInfo.back_number > 0"
                >{{ userInfo.back_number }} 份商品需要取回</span
              >
              <img
                class="arrow-right"
                :src="require('@/static/image/icons/arrowRight.png')"
              />
            </div>
          </cell-v3>
        </div>

        <div class="actions box-m-b-15" @click="goToDistribution">
          <cell-v3>
            <img
              slot="icon"
              class="cell-icon"
              :src="require('@/static/image/icons/delivery-info.png')"
            />
            <span class="title" slot="title">统一配送</span>
            <div class="action-icons">
              <span v-if="userInfo.back_number > 0"
                >{{ userInfo.back_number }} 份商品需要配送</span
              >
              <img
                class="arrow-right"
                :src="require('@/static/image/icons/arrowRight.png')"
              />
            </div>
          </cell-v3>
        </div>
      </div>
    </scrollview>

    <button class="btn" v-debounce="['click', goScanCode, 500]">
      扫码取件
    </button>
  </div>
</template>

<script>
import CellV3 from "../../template/community/cell/cellV3";

export default {
  name: "home",
  components: { CellV3 },
  data() {
    return {
      userInfo: {},
      scrollHeight: 0,
    };
  },
  mounted() {
    //计算容器的高度
    this.scrollHeight =
      window.innerHeight -
      document.querySelector(".courier-container").clientHeight;
    this.getUserInfo();
  },
  methods: {
    onRefresh(done) {
      this.getUserInfo().finally(() => {
        done();
      });
    },
    async getUserInfo() {
      try {
        let query = await this.$api.community.courier.getUserInfo();
        console.log(query);
        this.userInfo = query.data;
      } catch (e) {
        console.log("用户信息获取失败");
      }
    },
    goToNotice() {
      //跳转到取货通知页面
      if (this.$platform.wxsdk.isWechat()) {
        this.$platform.wxsdk.wxRoute({
          type: "navigateTo",
          url: "/web/courier/courier-notice",
        });
      } else {
        this.$router.push({ path: "courier-notice" });
      }
    },
    goToSendNotice() {
      //跳转到货品订单列表页面
      if (this.$platform.wxsdk.isWechat()) {
        this.$platform.wxsdk.wxRoute({
          type: "navigateTo",
          url: "/web/courier/send-notice",
        });
      } else {
        this.$router.push({ path: "send-notice" });
      }
    },
    goToOrderList() {
      if (this.$platform.wxsdk.isWechat()) {
        this.$platform.wxsdk.wxRoute({
          type: "navigateTo",
          url: "/web/courier/order-list",
        });
      } else {
        this.$router.push({ path: "order-list" });
      }
    },
    goToBackGoods() {
      if (this.$platform.wxsdk.isWechat()) {
        this.$platform.wxsdk.wxRoute({
          type: "navigateTo",
          url: "/web/courier/back-goods",
        });
      } else {
        this.$router.push({ path: "back-goods" });
      }
    },
    //扫码取件
    goScanCode() {
      if (this.$platform.wxsdk.isWechat()) {
        this.$platform.wxsdk.wxRoute({
          type: "navigateTo",
          url: "/web/scancode/courier",
        });
      }
    },
    goToMoneyCenter() {
      if (this.$platform.wxsdk.isWechat()) {
        this.$platform.wxsdk.wxRoute({
          type: "navigateTo",
          url: "/web/courier/money-center",
        });
      }
    },
    goToDistribution() {
      //跳转到统一配送页面
      if (this.$platform.wxsdk.isWechat()) {
        this.$platform.wxsdk.wxRoute({
          type: "navigateTo",
          url: "/web/courier/distribution",
        });
      } else {
        this.$router.push({ path: "distribution" });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.page-container {
  background: #f6f6f6;
  height: 100vh;

  .courier-container {
    height: 184px;
    background: linear-gradient(135deg, #ffb56d 0%, #ff8116 100%);
    position: relative;

    .house {
      width: 144px;
      position: absolute;
      right: 0;
      bottom: 0;
    }

    .avatar {
      width: 58px;
      height: 58px;
      background: #fff;
      -webkit-border-radius: 58px;
      -moz-border-radius: 58px;
      border-radius: 58px;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .realname {
      div {
        font-size: 14px;
        color: #fff;
        height: 29px;
      }
    }

    .info-container {
      width: 100%;
      height: 54px;
      background: #feeedc;
      border-radius: 16px 16px 0px 0px;
      display: flex;
      align-items: center;

      .info {
        flex: 1;
        height: 26px;
        display: flex;

        .money,
        .order-number {
          flex: 1;

          .big {
            height: 20px;
            font-size: 14px;
            color: #c44e11;
            display: flex;
            justify-content: center;
          }

          .small {
            height: 10px;
            font-size: 10px;
            color: #c44e11;
            display: flex;
            justify-content: center;
          }
        }

        .shuxian {
          width: 1px;
          border-left: 1px #f8dcc6 solid;
        }
      }
    }
  }

  .action-list {
    .actions {
      background: #fff;
      padding: 12px 21px;
      -webkit-border-radius: 8px;
      -moz-border-radius: 8px;
      border-radius: 8px;

      .cell-icon {
        width: 24px;
        height: 24px;
      }

      .title {
        color: #333333;
        font-size: 14px;
      }

      .action-icons {
        display: flex;
        align-items: center;

        span {
          color: #939393;
          font-size: 14px;
        }

        span.dot {
          background: #f46a17;
          color: #fff;
          padding: 2px 5px;
          -webkit-border-radius: 20px;
          -moz-border-radius: 20px;
          border-radius: 20px;
          margin-right: 5px;
        }

        .arrow-right {
          width: 14px;
          height: 14px;
        }
      }
    }
  }

  .btn {
    position: absolute;
    border: none;
    bottom: calc(20px + env(safe-area-inset-bottom));
    bottom: calc(20px + constant(safe-area-inset-bottom));
    transform: translateX(50%);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 193px;
    height: 66px;
    background: linear-gradient(139deg, #fac484 0%, #f46a17 100%);
    background-clip: padding-box;
    border: 4px solid rgba(244, 106, 23, 0.15);
    border-radius: 29px;
    font-size: 16px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ffffff;
  }
  .cell-v3 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    & > title {
      display: flex;
      align-items: center;
    }
  }
}
</style>
